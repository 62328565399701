@import 'themes';

@import '~@nebular/theme/styles/globals';
@import '~@nebular/auth/styles/globals';

@include nb-install() {
  @include nb-theme-global();
  @include nb-auth-global();
};
/* You can add global styles to this file, and also import other style files */

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@import 'scss/global.scss';
