@import 'colors';

nb-menu {
    a:hover {
        nb-icon svg {
            fill: $menu-hover;
        }
    }
}
//header
nb-layout-header {
  background-color: $blue;
  nav {
      color: white !important;
  }
  nb-icon {
      color: white;
  }
  .user-name {
      color: white !important;
  }
}
.theme-liquid {
    nb-layout-header {
        background-color: #009CDE !important;
    }
    .tryme-header,
    .tryme-powered-by {
        display: none;
    }
}

.datatable-header-cell-template-wrap {
  display: flex;
  align-items: baseline;
  position: relative;
  flex-wrap: wrap;
  .form-control-group {
    width: 100%;
    order: 1;
    input {
      max-width: 200px;
      width: 100%;
    }
    .appearance-outline.status-basic {
      width: 100%;
    }
    .select-button {
      max-width: 200px;
      min-width: auto;
      width: 100%;
    }
  }
}


.vertical-align-wrap.ngx-datatable {
  .datatable-body-cell {
    display: flex;
    align-items: center;
  }

  .datatable-row-wrapper {
    border-bottom: 1px solid #EDF1F7;
  }

  .datatable-body {
    &::-webkit-scrollbar-thumb {
      background: #62656C;
      border-radius: 25px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 25px;
      background: #eae8e8;
    }
    .datatable-row-right {
      right: -20px;
      width: 65px!important;
      overflow: hidden;
    }
    .price {
      display: flex;
      justify-content: flex-end;
    }
  }
  .datatable-header {
    .datatable-header-cell.checkbox-header-cell {
      display: flex;
      .datatable-header-cell-template-wrap {
        align-items: center;
      }
    }
    .datatable-row-right {
      background: #fff;
      width: 73px!important;
      right: -7px;
    }
  }
}
// user edit
.statistics-block {
  nb-tabset {
      ul {
        padding-bottom: 4px!important;
        overflow-y: hidden;
        overflow-x: scroll;
        &::-webkit-scrollbar-thumb {
          background: #62656C;
          border-radius: 25px;
        }

        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 25px;
          background: #eae8e8;
        }
      }
      .tab-link {
        text-transform: initial;
        font-weight: 400;
        outline: none;
      }
      .tab {
        margin-bottom: 0;
        &.active .tab-link {
          color: black;
          &:before {
            background-color: black;
            height: 1px;
            bottom: 1px !important;
          }
        }
      }
      .tab:hover {
        .tab-link {
          color: black;
          &::before {
            background-color: black;
            height: 1px;
            bottom: 1px !important;
          }
        }
      }
  }
}
.nb-theme-default nb-route-tabset {
  .route-tab {
    max-width: 300px;
  }

  .tab-link {
    text-transform: none;
    width: 100%;
    .tab-text {
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      display: inline-block;
      overflow: hidden;
    }
  }
}

.server-scrolling {
  height: calc(100vh - 330px);
}

.customer-glasses {
  .server-scrolling {
    height: calc(100vh - 425px);
  }
}
.header-wrap {
  .filter {
    nb-select .select-button {
      min-width: 100px!important;
    }
  }
}
// drag&drop
.form-wrap .ngx-file-drop__drop-zone {
  border: 4px dashed #E4E9F2 !important;
  border-radius: 4px !important;
  background: #F7F9FC;
  height: 192px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .ngx-file-drop__content {
    flex-direction: column;
  }
}

.error-for-field {
  color: #ff3d71;
}

textarea.for-copy {
  padding: 0.5rem;
  font-size: 1.1rem;
  line-height: 1.5;
  width: 100%;
  min-height: 100px;
  resize: none;
  overflow-y: auto;
  background-color: #f7f9fc;
  color: #222b45;
  border: none;
  font-family: Open Sans, sans-serif;
}
.datatable-scroller{
  width:100% !important;
}
.settings-form-wrap {
  .full-width button.select-button {
    min-width: auto;
  }
}
